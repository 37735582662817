@tailwind base;
@tailwind components;

@import './typography.css';
@import './global-allotment.css';
@import './global-animations.css';

::-webkit-scrollbar {
  @apply block h-2 w-2;
}

::-webkit-scrollbar-track {
  @apply rounded-xl bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-on-control-dimmed rounded-xl;
}

.scrollbar-gutter-stable {
  /* prevents layout shift when scrollbar shows only on hover */
  scrollbar-gutter: stable;
}

.checkbox {
  @apply border-on-control-main bg-surface checked:border-on-control-main checked:after:text-success-dark hover:!border-on-control-main focus:!border-on-control-main cursor-pointer rounded-md transition checked:!bg-none checked:after:text-lg checked:after:font-bold checked:after:leading-[0px] checked:after:content-['\2713'] focus:!border focus:outline-none;
  box-shadow: none !important;
  color: transparent;
}

/* DATE PICKER */

.react-datepicker {
  @apply !border-0;
}

.react-datepicker__header {
  @apply !bg-background !border-0;
}

.react-datepicker__month {
  @apply !bg-surface !border-0;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month {
  @apply !text-on-surface;
}

.react-datepicker__day--selected {
  @apply !bg-primary-cta !text-on-secondary;
}

.react-datepicker__navigation-icon:before {
  @apply !border-on-control-main;
}

.react-datepicker__day--keyboard-selected {
  @apply !bg-primary-variant !text-on-secondary;
}

.react-datepicker__navigation {
  @apply !h-[44px];
}

.react-datepicker__triangle {
  @apply hidden;
}

textarea {
  box-shadow: none !important;
}

button {
  box-shadow: none !important;
}

input {
  box-shadow: none !important;
  @apply cursor-pointer transition focus:!border focus:outline-none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input ~ .dot {
  transition: all 300ms;
}

input:checked ~ .dot {
  transform: translateX(24px);
}

/* used since normal borders dont work with sticky table headers */
.table-head-border {
  box-shadow:
    inset 0 0px 0 transparent,
    inset 0 -1px 0 var(--color-background-variant);
}

@tailwind utilities;
