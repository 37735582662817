.title-l {
  @apply !text-[32px] !font-normal;
}

.title-s {
  @apply !text-[23px] !font-extrabold;
}

.subtitle-1 {
  @apply !text-base !font-bold;
}

.subtitle-2 {
  @apply !text-sm !font-bold;
}

.body-1 {
  @apply !text-base !font-normal;
}

.body-2 {
  @apply !text-sm !font-normal;
}

.overline-text {
  @apply !text-sm !font-bold !tracking-[1.5px] !uppercase;
}

.text-unique {
  font-family: Inter, sans-serif;
  @apply !text-xs !font-bold !tracking-[1.5px] !uppercase;
}