@keyframes blinkdot {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.loading-text span {
  animation-name: blinkdot;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.loading-text span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-text span:nth-child(3) {
  animation-delay: 0.4s;
}
